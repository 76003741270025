import 'bootstrap/js/dist/util';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/popover';
import { JsCookies } from "../js/cookies";

let tooltips = document.querySelectorAll('[data-toggle="tooltip"]');
if(tooltips.length) {
    import(/* webpackChunkName: "bs-tooltip" */ 'bootstrap/js/dist/tooltip')
    .then(() => {
        for(var i = 0; i < tooltips.length; i++)
        {
            let tooltip = tooltips.item(i);
            $(tooltip).tooltip();
        } 
    })

}

let modals = document.querySelectorAll('[data-toggle="modal"]');
const popups = ($selector = $('[data-popup-modal]')) => {
    let $window = $(window);
    let $body = $('body');
    $window.on('scroll', function(){
        let height = (($body.outerHeight() + 300) / 2);
        if ($window.scrollTop() > height) {
            $selector.modal('show');
            $(window).unbind('scroll');
        }
    });
}

const modalEvents = ($selector = $('[data-modal-download]')) => {
	$selector.on('show.bs.modal', function (event) {
		var button = event.relatedTarget // Button that triggered the modal
		var title = button.dataset.title // Extract info from data-* attributes
		var field = button.dataset.field // Extract info from data-* attributes
		
		var modal = $(this)
		var fieldInput = modal.find(`[name="${field}"]`);
		fieldInput.val(title)
	  })
}

if(modals.length) {
    import(/* webpackChunkName: "bs-modal" */ 'bootstrap/js/dist/modal')
    .then(() => {
        console.log('loaded bs modal')
        popups()
		modalEvents()
    })
}

let dropdown = document.querySelectorAll('[data-toggle="dropdown"]');
if(dropdown.length) {

    import(/* webpackChunkName: "bs-dropdown" */ 'bootstrap/js/dist/dropdown')
    .then(() => {
        console.log('loaded bs dropdown')
    })
}
let tabs = document.querySelectorAll('[data-toggle="tab"]');
if(tabs.length) {
    import(/* webpackChunkName: "bs-tab" */ 'bootstrap/js/dist/tab')
    .then(() => {
        console.log('loaded bs tabs');
        (new JsCookies()).tabs();
    })
}

let alerts = document.querySelectorAll('[data-dismiss="alert"]');
if(alerts.length) {
    import(/* webpackChunkName: "bs-alert" */ 'bootstrap/js/dist/alert')
    .then(() => {
        console.log('alerts loaded')
    })
}

let collapses = document.querySelectorAll('.collapse');
if(collapses.length) {
    import(/* webpackChunkName: "bs-collapse" */ 'bootstrap/js/dist/collapse')
    .then(() => {
        console.log('loaded bs collapse')
    })
}

let toasts = document.querySelectorAll('.toast');
if(toasts.length) {
    import(/* webpackChunkName: "bs-toast" */ 'bootstrap/js/dist/toast')
    .then(() => {
        for(var i = 0; i < toasts.length; i++)
        {
            let toast = toasts.item(i);
            $(toast).toast('show');
        } 
    })
}
    