import {} from '../css/app.scss';
import "../js/fontawesome";
import '../js/bootstrap';
import '../js/lazysizes';

import '../img/blue_funeral_icon.svg'
import '../img/blue_health_icon.svg'
import '../img/blue_legal_icon.svg'
import '../img/smooth_loader.svg'


import { ExternalLinks } from "../js/externallinks";

import UIBindings from "../js/ui-bindings";
import { PageTimer } from "../js/pagetimer";
import Cookies from "js-cookie";


if(window.location.hash &&  window.location.hash == "#panel-1375" ){
    let url = window.location.href;
    window.location.replace(url.replace("1375", "99943"));
}

(function() {
    'use strict';
    let glides = document.getElementsByClassName('glide');
    let glide;
    if (glides.length) {
        new Promise((resolve) => {
            import(/* webpackChunkName: "glider" */ '../js/glide').
                then((Glider) => {
                    glide = new Glider.default(glides);
                    resolve(glide.initialize());
                });
        })
    }

    (new PageTimer()).logToConsole();
    (new ExternalLinks('body')).addLinkTarget();
    

    (new UIBindings())
        .bootstrap();

    let forms = document.getElementsByClassName('needs-validation');
    if(forms.length) {
    
        import(/* webpackChunkName: "forms" */ '../js/form')
            .then((Forms) => {
                let form =  new Forms.default(forms);
                form.submitButton().dateDropdown().dateOfBirth().validate().abSplit(Cookies.get('group'));
            })
    }
        
})();




// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}