import { library, dom, config } from '@fortawesome/fontawesome-svg-core';
import { 
    faEdit as falEdit, 
    faQuoteLeft as falQuoteLeft, 
    faQuoteRight as falQuoteRight, 
    faCheck as falCheck, 
    faTimes as falTimes, 
    faChevronDown as falChevronDown, 
    faChevronUp as falChevronUp, 
    faHeartbeat as falHeartbeat, 
    faTombstoneAlt as falTombstoneAlt, 
    faBalanceScale as falBalanceScale, 
    faEraser as falEraser, 
    faEnvelope as faEnvelopeLight, 
    faPhoneAlt as faPhoneAltLight, 
    faBrowser as faBrowserLight,
    faBells as faBellsLight
} from '@fortawesome/pro-light-svg-icons';
import { 
    faSearch as farSearch,
    faCircle as farCircle,
    faChevronLeft as farChevronLeft,
    faChevronRight as farChevronRight,
    faCheck as farCheck,
    faPlus as farPlus 
} from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight,
    faChevronLeft,
    faChevronDown,
    faCircle as fasCircle,
    faSearch as fasSearch,
    faChevronUp as fasChevronUp,
    faLink as fasLink,
    faDownload as fasDownload,
    faEdit as fasEdit,
    faSpinner as fasSpinner,
    faUser as fasUser,
    faShoppingCart as fasShoppingCart,
    faHashtag as fasHashtag,
    faBookmark as fasBookmark,
    faTimesCircle as fasTimesCircle,
    faInfoCircle as fasInfoCircle 
} from '@fortawesome/pro-solid-svg-icons';
import { 
    faFacebookF,
    faTwitter,
    faLinkedin,
    faYoutube 
} from '@fortawesome/free-brands-svg-icons';

config.searchPseudoElements = true;
config.keepOriginalSource = false;

library.add(
    // light
    falEdit,
    falQuoteLeft,
    falQuoteRight,
    falCheck,
    falTimes,
    falChevronDown,
    falChevronUp,
    falHeartbeat,
    falTombstoneAlt,
    falBalanceScale,
    falEraser,
    faBrowserLight,
    faPhoneAltLight,
    faEnvelopeLight,
    faBellsLight,

    // regular
    farSearch,
    farCircle,
    farChevronLeft,
    farChevronRight,
    farCheck,
    farPlus,

    // solid
    faChevronRight,
    faChevronLeft,
    faChevronDown,
    fasCircle,
    fasSearch,
    fasChevronUp,
    fasLink,
    fasDownload,
    fasEdit,
    fasSpinner,
    fasUser,
    fasShoppingCart,
    fasHashtag,
    fasBookmark,
    fasTimesCircle,
    fasInfoCircle,
    fasHashtag,

    // brands
    faFacebookF,
    faTwitter,
    faLinkedin,
    faYoutube
);

dom.i2svg();
dom.watch();