import Cookies from "js-cookie";

export class JsCookies {
    tabs() {
        $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            Cookies.set('activetab', e.target.id, { expires: 1 });
        });

        // because of {% cache %}
        var activeTabCookie = Cookies.get('activetab');
        $('[data-toggle="tab"][id="' + activeTabCookie + '"]').tab('show');
    }
}