
export default class UIBindings {
  
    bootstrap() {

        const triggerFormSumbit = (e) => {
            let form = $(e.target).closest('form');
            $(form).addClass('submitting').trigger('submit'); 
        }

        $('.custom-file').on('change','.custom-file-input',function(){
            //get the file name
            var str = this.value;
            var strIndexOf = str.replace(/\\/g, '/');
            var filename = strIndexOf.split("/").pop();
            //replace the "Choose a file" label
            $(this).next('.custom-file-label').html(filename);
        });

        let $body = $('body');

        $('[data-toggle="offcanvas"]').on('click', function () {
            let target = $(this).data('target');
            $(this).toggleClass('collapsed');
            $(target).toggleClass('open');
            $body.toggleClass('overflow-hidden');
        });

        $('[data-toggler]').on('click', function(){
        	let offcanvas = $(this).data('offcanvas');
        	let tab = $(this).data('tab');
            if (tab) { $('[href="' + tab + '"]').tab('show'); }
            if (offcanvas) { $(offcanvas).trigger('click'); }
        });

        $('[data-toggle="submit-form"] input[type="radio"]').on('click', triggerFormSumbit);

        $('select').on('change', function (e) {
          console.log('Select change value', 'value', this.value, 'name',  this.name,'id',  this.id);
          var val = this.value ? this.value : 'none';
          	$('.' + this.id).each(function () {
                $(this).find('input[type="text"]').attr('value', '');
                $(this).find('input').prop('checked', false);
                $(this).find('select option:selected').prop('selected', false);
                $(this).find('select').prop('selectedIndex', 0);
            }).removeClass('show').filter('.' + val).addClass('show');

            if(this.value == "giveMyBankingDetails" && this.id == 'fields-howWouldYouLikeToPay'){
                let chekboxes = document.getElementsByClassName('custom-checkbox');
                for (let i = 0; i < chekboxes.length; i++) {
                    if( chekboxes[i].childNodes[0].name == "fields[bankAccountConfirmation][]"){
                        
                        document.getElementById(chekboxes[i].childNodes[0].id).checked = false;
                        document.getElementById(chekboxes[i].childNodes[0].id).checked = true;
                    }
                }
            }

            if(this.id == "fields-whatDoYouNeedHelpWith") {
                var form = this.closest('form');
                if (form.closest('div').getAttribute('id')=== 'service') {
                    var getRedirectUrl = form.querySelector('[data-id="' + this.value + '"]').getAttribute('data-redirect-url');
                    form.querySelector('[name="redirect"]').value = getRedirectUrl;
                }
            }
        });

        
        $('input[type="radio"]').on('change', function(e) {
            var rateService = Array.from(document.getElementsByName('fields[rateService]')).filter(e => {
                return e.checked;
            })[0];

            var ratePerson = Array.from(document.getElementsByName('fields[ratePerson]')).filter(e => {
                return e.checked;
            })[0];


            var ratePersonVal = ratePerson ? ratePerson.value : 5;
            var rateServiceVal = rateService ? rateService.value : 5;
            
            if(this.name.match(/rate/) && (ratePersonVal < 3 || rateServiceVal < 3) ) {
                $('.fields-rate').addClass('show');
            } else if (this.name.match(/rate/)) {
                $('.fields-rate').removeClass('show');
            }
        })

        $('[data-form-uid]').each(function () {
            var form = $(this);
            form.find('[name="fields[uidHash]"]').val(form.data('form-uid'));
            form.find('[name="fields[hidden]"]').val(form.data('step'));
            form.find('[name="fields[referralUrl]"]').val(form.data('referralUrl'));
            // UTM
            form.find('[name="fields[utm_medium]"]').val(form.data('utmMedium'));
            form.find('[name="fields[utm_source]"]').val(form.data('utmSource'));
            form.find('[name="fields[utm_campaign]"]').val(form.data('utmCampaign'));
        });

         var waitedForResponse = function defer(method) {
            if (grecaptcha.getResponse())
                method();
            else
                setTimeout(function () {
                    defer(method)
                }, 50);
        };

        $(window).on('load',function(){
            
            var form = $('[embedded-subscribe-form]');

            form.on('submit', function(event){
                event.preventDefault();

                var self = this;
                var fields = {};
                var fromEmail = $('#mce-EMAIL').val();
                var firstName = $('#mce-FNAME').val();
                var lastName = $('#mce-LNAME').val();
                var mobileNumber = $('#mce-MMERGE6').val();

                fields['fromEmail'] = fromEmail;
                fields['firstName'] = firstName;
                fields['lastName'] = lastName;
                fields['mobileNumber'] = mobileNumber;

                if($('#mce-MMERGE17-1').is(':checked')){
                    fields['lead_sync'] = 1;
                }
                fields['productSelection'] = 'Promo_Newsletter';
                fields['post_passive'] = 1;
    
                // waitedForResponse(function(){
                //     fields['verifyCode'] = 1;
                    $.ajax({
                        type: "POST",
                        url : "/",
                        async: true,
                        data: {
                            fields: fields,
                            action: 'guest-entries/save',
                            sectionId: 7,
                            typeId: 10
                        }
                    })
                    .always(function(){
                        $(self).unbind();
                        self.submit();
                    });
                // })                
            });
        });
        return this;
    }
}